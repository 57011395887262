<template>
  <p :class="styles">
    <component
      :is="!!onClick ? 'a' : 'span'"
      @click="onClick()"
      :class="{ 'is-underlined': !!onClick }"
    >
      {{ value }}
    </component>
    <component
      :is="tooltip ? 'a' : 'span'"
      class="icon is-marginless"
      :title="tooltip"
      v-tippy="{ placement: 'right' }"
      v-if="icon"
    >
      <i :class="icon" />
    </component>
  </p>
</template>

<script>
export default {
  name: 'DataTableBlockText',
  inheritAttrs: true,
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    tooltip: {
      type: String,
      required: false,
      default: null
    },
    styles: {
      type: String,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    onClick: {
      type: Function,
      required: false,
      default: null
    }
  }
}
</script>

<style lang="sass" scoped>
p
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
</style>
